import ActivityLog from "@/components/activities/activityLog/activityLog";
import Layout from "@/components/layout";
import { fetchActivities } from "@/redux/api_methods";
import { t } from "@/utils/translation";
import { ReactElement, useEffect } from "react";
import { useAuth } from "../auth";
import { NextPageWithLayout } from "./_app";

const Home: NextPageWithLayout = () => {
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    fetchActivities();
  }, []);
  return (
    <div>
      <ActivityLog />
    </div>
  );
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Home;
