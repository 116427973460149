import { HorseActivity } from "@/openapi";
import { fetchActivities } from "@/redux/api_methods";
import { IStore } from "@/redux/store";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import Spinner from "../../misc/Spinner";
import ActivityLogCard from "./activityLogCard";

interface IActivityLogProps {
  activities: HorseActivity[] | undefined;
}
const ActivityLog = ({ activities, ...props }: IActivityLogProps) => {
  const handleScrollListen = (e: Event) => {
    const element = ref.current;
    if (!element) {
      return;
    }
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // you're at the bottom of the page

      fetchActivities(true);
    }
  };
  useEffect(() => {
    addEventListener("scroll", handleScrollListen);
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref} className="mb-16">
      {!activities ? (
        <Spinner className="flex flex-col items-center mt-8" />
      ) : (
        activities.map((a) => {
          return <ActivityLogCard key={"activity" + a.id} activity={a} />;
        })
      )}
    </div>
  );
};

const mapStateToProps = (state: IStore) => {
  const { activities } = state.activityState;
  return { activities };
};

export default connect(mapStateToProps)(ActivityLog);
